import React from "react";
import { DataLogos } from "../../data/DataSobre";
import { Item, ImgLogo } from "./CarouselLogos.elements";
import Slider from "react-slick";

const CarouselLogos = () => {

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 5,
    speed: 500,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
  
      <Slider {...settings}>

        {DataLogos.map((logo, index) => (
          <Item key={index}>
            <ImgLogo src={logo.image} alt={logo.alt} />
          </Item>
        ))}

      </Slider>

  )
}

export default CarouselLogos