import React, { Component } from "react";
import { Case } from "../../components";
import { DataCases } from "../../data/DataCases";
class Cases extends Component {
  state = {
    post: DataCases
  };

  render() {
    return (
        <Case data={this.state.post} />
    );
  }
}

export default Cases