import React, { useState } from "react";
import { FaTimes, FaBars } from 'react-icons/fa'
import { IconContext } from "react-icons/lib";
import {
    Nav,
    NavbarContainer,
    NavLogo,
    Logo,
    MobileIcon,
    NavMenu,
    NavItem,
    NavLinks,
    Contato
} from "./Navbar.elements";
import logoScale from '../../images/logos/logo_scale.png'

const Navbar = () => {

    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)
    const [navbar, setNavbar] = useState(false);

    const changeNavbarColor = () => {
        if (window.scrollY >= 80) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }

    window.addEventListener('scroll', changeNavbarColor, true);

    return (
        <IconContext.Provider value={{ color: '#ee3325' }}>
            <Nav className={navbar ? 'white' : 'black'}>
                <NavbarContainer>
                    <NavLogo>
                        <NavLinks
                            to='home'
                            activeClass="active"
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}>
                            <Logo src={logoScale} />
                        </NavLinks>
                    </NavLogo>

                    <MobileIcon onClick={handleClick}>
                        {click ? <FaTimes /> : <FaBars />}
                    </MobileIcon>
                    <NavMenu onClick={handleClick} click={click}>
                        <NavItem>
                            <NavLinks 
                            to='home'
                            onClick={handleClick}
                            activeClass="active"
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}>home</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks 
                            to='cases'
                            onClick={handleClick}
                            activeClass="active"
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}>cases</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks 
                            to='sobre'
                            onClick={handleClick}
                            activeClass="active"
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}>sobre</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks 
                            to='servicos'
                            onClick={handleClick}
                            activeClass="active"
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}>serviços</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks 
                            to='contato'
                            onClick={handleClick}
                            activeClass="active"
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}><Contato>contato</Contato></NavLinks>
                        </NavItem>
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </IconContext.Provider>
    )
}

export default Navbar;