import styled from "styled-components";

export const AnimateCase = styled.div`
  &.fade-in-case{
  opacity: 0;
  transform: translateY(-20%);
  visibility: hidden;
  transition: .5s, opacity .6s ease-in-out, transform 1s ease-in-out;
  will-change: opacity, visibility;

    &.case-visible {
        opacity: 1;
        transform: none;
        visibility: visible;
    }
  }
`

export const AnimateLeft = styled.div`
  &.fade-in-left{
  opacity: 0;
  transform: translateX(-20%);
  visibility: hidden;
  transition: .5s, opacity .6s ease-in-out, transform 1s ease-in-out;
  will-change: opacity, visibility;
  @media screen and (max-width: 768px){
    transform: translateX(0%);
  }
    &.left-visible {
        opacity: 1;
        transform: none;
        visibility: visible;
    }
  }
`

export const AnimateRight = styled.div`
  &.fade-in-right{
  opacity: 0;
  transform: translateX(20%);
  visibility: hidden;
  transition: .5s, opacity .6s ease-in-out, transform 1s ease-in-out;
  will-change: opacity, visibility;
  @media screen and (max-width: 768px){
    transform: translateX(0%);
  }
    &.right-visible {
        opacity: 1;
        transform: none;
        visibility: visible;
    }
  }
`