export const DataVideos = [
    {
        videoWebm: require('../images/video/video_home.webm'),
        videoMp4: require('../images/video/video_home.mp4'),
        title: 'Propósito &\natitude &\ntécnica.'
    },
    {
        videoWebm: require('../images/video/video_proposito.webm'),
        videoMp4: require('../images/video/video_proposito.mp4'),
        title: 'Propósito &\natitude &\ntécnica.'
    },
];

// export const Videos = {
//     videoWebm: require('../images/video/video_home.webm'),
//     videoMp4: require('../images/video/video_home.mp4'),
//     title: 'Propósito &\natitude &\ntécnica.'
// }
// export const DataVideos = [
// {
//     title: 'ATITUDE',
//     subtitle: 'É poder tudo mudar',
//     videoWebm: require('../images/video/video_atitude.webm'),
//     videoMp4: require('../images/video/video_atitude.mp4')
// },
// {
//     title: 'PAIXÃO',
//     subtitle: 'É estar, ser e lutar',
//     videoWebm: require('../images/video/video_paixao.webm'),
//     videoMp4: require('../images/video/video_paixao.mp4')
// },
// {
//     title: 'PROPÓSITO',
//     subtitle: 'É o que nos impulsiona a ação',
//     videoWebm: require('../images/video/video_proposito.webm'),
//     videoMp4: require('../images/video/video_proposito.mp4')
// },
// ];