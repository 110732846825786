import styled from "styled-components";


export const CardContent = styled.div`
  position: relative;
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  padding-top: ${(props) => props.pt};
  padding-bottom: ${(props) => props.pb};

  @media screen and (max-width: 991px) {
    width: 100%;
    flex-direction: column;
  }
`
export const CardCarousel = styled.div`
  max-width: 960px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
export const CardLeft = styled.div`
    width: 50%;
    padding: 0 15px;

@media screen and (max-width: 768px) {
    width: 100%;
  }
`
export const CardRight = styled.div`
  width: 50%;
  padding: 0 15px;
  text-align: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 15px;
  }
`
export const ImageSobre = styled.img`
  max-width: 480px;
  margin: 0 auto;
`
export const CardTitle = styled.h2`
    color: #ffffff;
    font-weight: 900;
    font-size: ${(props) => props.size};
    padding-top: ${(props) => props.pt};
    padding-bottom: ${(props) => props.pb};
    color: ${(props) => props.color};

    @media screen and (max-width: 768px) {
      width: 100%;
      position: initial;
      padding: 0 15px 0;
  }
`
export const CardText = styled.p`
  color: #ffffff;
  font-weight: 400;
  text-align: left;
  font-size: 1rem;
  padding-bottom: ${(props) => props.pb};

  @media screen and (max-width: 768px) {
    text-align: initial;
  }
`
