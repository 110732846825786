export const TitleSection = {
    letter_red: 'c',
    title_complete: 'ases',
}
export const DataCases = [
    {
        id: 1,
        class_modal: "modalDivi",
        image: require('../images/cases/divihub.jpg'),
        text_button: "Saiba mais",
        alt_image: "Case DIVI-Hub",
        title_card: "DIVI.Hub",
        hat_card: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna.Lorem ipsum dolor...",
        client: require('../images/logos/divihub-logo.png'),
        clientName: "DIVI.Hub",
        project: "Desenvolvimento do App DIVI.Hub",
        segment: "Investimento",
        title_aplication_one: "DIVI.Hub:",
        description: "DiviHub – Fintech de Crowdfunding regulamentado pela CVM, com segurança e rastreabilidade.",
        title_aplication_two: "Quem tem DIVI soma:",
        description_two: "O grande desafio desse projeto foi dar continuidade ao trabalho iniciado por outra empresa e concluir garantindo que todos os requisitos fossem atendidos. A entrega e acompanhamento do projeto está sendo de enorme satisfação para toda a equipe Scale, pela complexidade das demandas exigidas pelo projeto, sobretudo da estrutura de contas digitais incorporada.",
        description_three: "Contando com equipe multidisciplinar Scale e utilizando os modernos recursos AWS, fomos capazes de construir uma arquitetura simples e muito escalável para atender à demanda projetada. Tecnologias como Python, React.js, React-Native, Node, Lambda, DynamoDB, MySQL e QLDB fazem parte dessa stack.",

        imgModal: [
            {
                id: 1,
                img: require('../images/cases/produtos/divihub1.jpg'),
                dimension: "100%",
                alt: "Case DIVI-Hub",
            },
            {
                id: 2,
                img: require('../images/cases/produtos/divihub2.jpg'),
                dimension: "100%",
                alt: "Case DIVI-Hub",
            },
            {
                id: 3,
                img: require('../images/cases/produtos/divihub3.jpg'),
                dimension: "100%",
                alt: "Case DIVI-Hub",
            },
            {
                id: 4,
                img: require('../images/cases/produtos/divihub4.jpg'),
                dimension: "100%",
                alt: "Case DIVI-Hub",
            },
            {
                id: 5,
                img: require('../images/cases/produtos/divihub5.jpg'),
                dimension: "100%",
                alt: "Case DIVI-Hub",
            },
            {
                id: 6,
                img: require('../images/cases/produtos/divihub6.jpg'),
                dimension: "100%",
                alt: "Case DIVI-Hub",
            },
        ]
    },
    {
        id: 2,
        class_modal: "modalCineclick",
        image: require('../images/cases/cineclick.jpg'),
        text_button: "Saiba mais",
        alt_image: "Case Cineclick",
        title_card: "Cineclick",
        hat_card: "O projeto foi feito a 4 mãos, Atuamos na construção técnica, integrações e infraestrutura...",
        client: require('../images/logos/cineclick-logo.png'),
        logoModal: require('../images/logos/cineclick-logo-cinza.png'),
        clientName: "Cineclick",
        project: "Portal de Cinema",
        segment: "Entretenimento, Filmes, Séries",
        title_aplication_one: "Portal de cinema:",
        description: "Projeto da nova versão do portal do mundo do cinema, Cineclick. O projeto foi feito a 4 mãos, Atuamos na construção técnica, integrações e infraestrutura enquanto o design foi criado internamente pela equipe Flix Media. O Portal acaba de entrar no ar e apresenta de forma diferenciada as informações gerais do mundo do cinema.",
        imgModal: [
            {
                id: 1,
                img: require('../images/cases/produtos/cineclick1.JPG'),
                alt: "Case Cineclick",
            },
            {
                id: 2,
                img: require('../images/cases/produtos/cineclick2.JPG'),
                alt: "Case Cineclick",
            },
            {
                id: 3,
                img: require('../images/cases/produtos/cineclick3.JPG'),
                alt: "Case Cineclick",
            },
            {
                id: 4,
                img: require('../images/cases/produtos/cineclick4.JPG'),
                alt: "Case Cineclick",
            },
            {
                id: 5,
                img: require('../images/cases/produtos/cineclick5.JPG'),
                alt: "Case Cineclick",
            },
            {
                id: 6,
                img: require('../images/cases/produtos/cineclick6.JPG'),
                alt: "Case Cineclick",
            },
            {
                id: 7,
                img: require('../images/cases/produtos/cineclick7.JPG'),
                alt: "Case Cineclick",
            },
        ]
    },
    {
        id: 3,
        class_modal: "modalAlfred",
        image: require('../images/cases/alfred.jpg'),
        text_button: "Saiba mais",
        alt_image: "Case Alfred",
        title_card: "Alfred",
        hat_card: "Entramos no projeto depois de pronto para dar continuidade ao desenvolvimento e melhorias...",
        client: require('../images/logos/alfred-logo.png'),
        clientName: "Alfred",
        project: "Aplicativo Alfred",
        segment: "Cinema",
        title_aplication_one: "Alfred – Cinema do seu jeito:",
        description: "App dos adoradores de cinema. Entramos no projeto depois de pronto para dar continuidade ao desenvolvimento e melhorias. Refizemos a intranet de gestão do app em nova linguagem e melhor usabilidade, terminamos a ferramenta de disparos de notificações da maneira correta e implementamos novas funcionalidades. Estamos em constante desenvolvimento e cuidamos da sustentação do projeto.",
        imgModal: [
            {
                id: 1,
                img: require('../images/cases/produtos/alfred1.jpg'),
                dimension: "100%",
                alt: "Case App Alfred",
            },
            {
                id: 2,
                img: require('../images/cases/produtos/alfred2.jpg'),
                dimension: "100%",
                alt: "Case App Alfred",
            },
            {
                id: 3,
                img: require('../images/cases/produtos/alfred3.jpg'),
                dimension: "100%",
                alt: "Case App Alfred",
            },
            {
                id: 4,
                img: require('../images/cases/produtos/alfred4.jpg'),
                dimension: "100%",
                alt: "Case App Alfred",
            },
            {
                id: 5,
                img: require('../images/cases/produtos/alfred5.jpg'),
                dimension: "100%",
                alt: "Case App Alfred",
            },
            {
                id: 6,
                img: require('../images/cases/produtos/alfred6.jpg'),
                dimension: "100%",
                alt: "Case App Alfred",
            },
            {
                id: 7,
                img: require('../images/cases/produtos/alfred7.jpg'),
                dimension: "100%",
                alt: "Case App Alfred",
            },
        ]
    },
    {
        id: 4,
        class_modal: "modalFlix",
        image: require('../images/cases/flix-media.jpg'),
        text_button: "Saiba mais",
        alt_image: "Case Flix Media",
        title_card: "Flix Media - ADV",
        hat_card: "A primeira versão foi criado pela Scale há 10 anos e é o principal sistema de gestão...",
        client: require('../images/logos/flix-logo.png'),
        clientName: "Flix Media",
        project: "Comercialização de Mídia",
        segment: "Cinema, Marketing, Mídia",
        title_aplication_one: "Tecnologias:",
        description: "Segunda versão do Sistema Advertisign, está em desenvolvimento neste momento. A primeira versão foi criado pela Scale há 10 anos e é o principal sistema de gestão e operação da empresa. A Flix Media é representante comercial de mídias das maiores empresas de cinema do Brasil e américa latina. Este sistema é fundamenta e ajuda na gestão comercial, na gestão operacional e faturamento. Cliente Scale há 10 anos.",
        title_aplication_two: "ADV2 – Advertising Media:",
        description_two: "Tecnologias: Vue Js, XHTML, CSS, AJAX, jQuery, JSF, Java, JBoss",
        imgModal: [
            {
                id: 1,
                img: require('../images/cases/produtos/flixadv1.JPG'),
                alt: "Case Flix ADV2",
            },
            {
                id: 2,
                img: require('../images/cases/produtos/flixadv2.JPG'),
                alt: "Case Flix ADV2",
            },
            {
                id: 3,
                img: require('../images/cases/produtos/flixadv3.JPG'),
                alt: "Case Flix ADV2",
            },
            {
                id: 4,
                img: require('../images/cases/produtos/flixadv4.JPG'),
                alt: "Case Flix ADV2",
            },
            {
                id: 5,
                img: require('../images/cases/produtos/flixadv5.jpg'),
                alt: "Case Flix ADV2",
            },
        ]
    },
    {
        id: 5,
        class_modal: "modalCinesystem",
        image: require('../images/cases/clube-da-pipoca.jpg'),
        text_button: "Saiba mais",
        alt_image: "Case Clube da Pipoca",
        title_card: "Cinesystem",
        hat_card: "Com grandes problemas de desempenho do site, área administrativa e aplicativo, a...",
        client: require('../images/logos/cinesystem-logo2.png'),
        logoModal: require('../images/logos/cinesystem-logo.png'),
        clientName: "Clube da Pipoca",
        project: "Site, Clube da Pipoca, App",
        segment: "Cinema",
        title_aplication_one: "Portal de cinema:",
        description: "Projeto de renovação do portal do exibidor Cinesystem. Transformamos a linguagem de comunicação do site, tornando a informação mais direta e clara com visual arrojado do mundo do cinema. Empregamos forte tecnologia para entregar um site responsivo integrado a diversas fontes de informação, dados vindos de sistemas próprios e de terceiros.",
        title_aplication_two: "Aplicativo – IOS e Android:",
        description_two: "Criação e desenvolvimento do App Cinesystem. Projeto executado juntamente com novo Portal e programa de fidelidade da Cinesystem. O App apresenta aos clientes as informações do portal sobre programação e também todo o relacionamento do programa de fidelidade Clube da Pipoca.",
        title_aplication_three: "Programa de fidelidade Clube da Pipoca:",
        description_three: "Projeto de refatoração do Programa de fidelidade Clube da Pipoca. O Objetivo foi modernizar a plataforma, tornando mais robusta e independente de sistemas terceiros. Todas as regras de negócio foram migradas para a nova plataforma que se tornou independente de sistemas legados e de sistemas de parceiros.",
        title_aplication_four: "Gestor interno Clube da Pipoca:",
        description_four: "Área administrativa do Programa de Fidelidade Clube da Pipoca. Central de informação e gestão do Programa de Fidelidade. Através desta solução é possível acompanhar e entender o comportamento dos usuários durante sua jornada de relacionamento com a empresa. Aqui é possível criar promoções e ações de relacionamento aferindo os resultados. Cliente Scale há 4 anos.",
        imgModal: [
            {
                id: 1,
                img: require('../images/cases/produtos/cinesystem1.jpg'),
                alt: "Case Cinesystem Clube da Pipoca",
            },
            {
                id: 2,
                img: require('../images/cases/produtos/cinesystem2.jpg'),
                alt: "Case Cinesystem Clube da Pipoca",
            },
            {
                id: 3,
                img: require('../images/cases/produtos/cinesystem3.jpg'),
                alt: "Case Cinesystem Clube da Pipoca",
            },
            {
                id: 4,
                img: require('../images/cases/produtos/cinesystem4.jpg'),
                alt: "Case Cinesystem Clube da Pipoca",
            },
            {
                id: 5,
                img: require('../images/cases/produtos/cinesystem5.jpg'),
                alt: "Case Cinesystem Clube da Pipoca",
            },
            {
                id: 6,
                img: require('../images/cases/produtos/cinesystem6.jpg'),
                alt: "Case Cinesystem Clube da Pipoca",
            },
        ]
    },
    {
        id: 6,
        class_modal: "modalKinoplex",
        image: require('../images/cases/kinoplex.jpg'),
        text_button: "Saiba mais",
        alt_image: "Case Kinoplex",
        title_card: "Kinoplex",
        hat_card: "Ao solicitar a construção do App da Kinoplex, os objetivos iniciais eram a praticidade...",
        client: require('../images/logos/kinoplex-logo.png'),
        logoModal: require('../images/logos/kinoplex-logo2.png'),
        clientName: "Kinoplex - Cinema ao máximo",
        project: "Aplicativo Kinoplex - Desafio de sair do lugar comum",
        segment: "Cinema",
        title_aplication_one: "Portal de cinema:",
        description: "Nosso relacionamento com Kinoplex é perene e sólido. Somos fornecedores de tecnologia desde 2012 com uma primeira versão do site e sistemas internos de programação de cinema. Esta já é a terceira versão do portal sob nossa responsabilidade. Em todas as versões a inovação na maneira como apresentar as informações e uso de novas tecnologias foram diferenciais. Cliente Scale há 9 anos.",
        title_aplication_two: "Aplicativo:",
        description_two: "Ao solicitar a construção do App da Kinoplex, os objetivos iniciais eram a praticidade e a facilidade de se encontrar as informações de programação dos filmes, sem perder a possibilidade de se comunicar bem com o cliente. O App, desenvolvido em React Native, apresentou excelente desempenho, trazendo aos usuários a grande facilidade de encontrar seus filmes e cinemas desejados e compra de ingressos com poucos cliques, utilizando a API da Ingresso.com Cliente Scale há 9 anos.",
        imgModal: [
            {
                id: 1,
                img: require('../images/cases/produtos/kinoplex1.jpg'),
                dimension: "100%",
                alt: "Case App Kinoplex",
            },
            {
                id: 2,
                img: require('../images/cases/produtos/kinoplex2.jpg'),
                dimension: "100%",
                alt: "Case App Kinoplex",
            },
            {
                id: 3,
                img: require('../images/cases/produtos/kinoplex3.jpg'),
                dimension: "100%",
                alt: "Case App Kinoplex",
            },
            {
                id: 4,
                img: require('../images/cases/produtos/kinoplex4.jpg'),
                dimension: "100%",
                alt: "Case App Kinoplex",
            },
            {
                id: 5,
                img: require('../images/cases/produtos/kinoplex5.jpg'),
                dimension: "100%",
                alt: "Case App Kinoplex",
            },
            {
                id: 6,
                img: require('../images/cases/produtos/kinoplex6.jpg'),
                dimension: "100%",
                alt: "Case App Kinoplex",
            },
        ]
    },
    {
        id: 7,
        class_modal: "modalUniversal",
        image: require('../images/cases/universal-exibidor.jpg'),
        text_button: "Saiba mais",
        alt_image: "Case Universal Exibidor",
        title_card: "Universal Exibidor",
        hat_card: "Portal Exibidor é um de nossos produtos empresariais que resolve o problema de gestão de conteúdo...",
        client: require('../images/logos/universal-logo.png'),
        logoModal: require('../images/logos/universal-logo2.png'),
        clientName: "Universal Exibidor",
        project: "Portal Exibidor:",
        segment: "Distribuidor, Filmes",
        title_aplication_one: "Portal Exibidor:",
        description: "Portal Exibidor é um de nossos produtos empresariais que resolve o problema de gestão de conteúdo sensível do cliente a seus parceiros. Através do Portal Exibidor é possível controlar o acesso ao portal e também individualmente a cada conteúdo, obtendo relatório de acesso a cada informação. Cliente Scale há 2 anos.",
        title_aplication_two: "Site de conteúdo Lojas e Franqueados:",
        imgModal: [
            {
                id: 1,
                img: require('../images/cases/produtos/universalexibidor1.jpg'),
                alt: "Case Universal Exibidor",
            },
            {
                id: 2,
                img: require('../images/cases/produtos/universalexibidor2.jpg'),
                alt: "Case Universal Exibidor",
            },
            {
                id: 3,
                img: require('../images/cases/produtos/universalexibidor3.jpg'),
                alt: "Case Universal Exibidor",
            },
            {
                id: 4,
                img: require('../images/cases/produtos/universalexibidor4.jpg'),
                alt: "Case Universal Exibidor",
            },
            {
                id: 5,
                img: require('../images/cases/produtos/universalexibidor5.jpg'),
                alt: "Case Universal Exibidor",
            },
            {
                id: 6,
                img: require('../images/cases/produtos/universalexibidor6.jpg'),
                alt: "Case Universal Exibidor",
            },
        ]
    },
    {
        id: 8,
        class_modal: "modalLevis",
        image: require('../images/cases/levis.jpg'),
        text_button: "Saiba mais",
        alt_image: "Case Portal Levi`s",
        title_card: "Levi`s",
        hat_card: "Sem visão gerencial adequada das vendas das lojas e franquias Levi's no Brasil, a Scale...",
        client: require('../images/logos/levis-logo.png'),
        logoModal: require('../images/logos/levis-logo.png'),
        clientName: "Levi`s",
        project: "Dashboard gerencial de vendas",
        segment: "Moda, Vestuário",
        title_aplication_one: "Desafio e Solução:",
        description: "Sem visão gerencial adequada das vendas das lojas e franquias Levi's no Brasil, a Scale construiu um dashboard que reuniu a informação de venda, tráfego e de ecommerce de mais de 70 pontos comerciais, cujos sistemas de vendas e ERP divergiam completamente, trazendo soluções simples e adaptadas para cada cenário, possibilitando as áreas gerenciais e estratégica monitorarem as vendas nacionais.",
        title_aplication_two: "Site de conteúdo Lojas e Franqueados:",
        description_two: "Projeto de Extranet para Lojas próprias e Franqueados. O objetivo do projeto foi criar repositório de informações importantes, restritas por senha para funcionários das lojas próprias e franqueados. Todo tipo de informação é transmitida neste canal, informações financeira, treinamos, lançamentos de coleções, etc. Cliente Scale há 5 anos.",
        title_aplication_three: "App Checklist e SAC:",
        description_three: "Checklist – Criado sob demanda para agilizar a gestão de Visual Merchandising. Aproxima as ações das mais de 70 lojas no Brasil, padronizando lançamentos de coleções. É possível verificar através de fotos como cada loja montou sua coleção, trocar dicas e áudios com instruções de melhorias. SAC – Criado para centralizar o processo de trocas de produtos pelos clientes nas lojas Levi’s. Acompanha o processo do início ao fim, inclusive com a participação do cliente. Ambas funcionalidades estão em um mesmo App utilizado pelos funcionários nas lojas próprias e franquias.",
        imgModal: [
            {
                id: 1,
                img: require('../images/cases/produtos/portallevis1.jpg'),
                alt: "Case Portal Levi`s",
            },
            {
                id: 2,
                img: require('../images/cases/produtos/portallevis2.jpg'),
                alt: "Case Portal Levi`s",
            },
            {
                id: 3,
                img: require('../images/cases/produtos/portallevis3.jpg'),
                alt: "Case Portal Levi`s",
            },
            {
                id: 4,
                img: require('../images/cases/produtos/portallevis4.jpg'),
                alt: "Case Portal Levi`s",
            },
            {
                id: 5,
                img: require('../images/cases/produtos/portallevis5.jpg'),
                alt: "Case Portal Levi`s",
            },
        ]
    },
    {
        id: 9,
        class_modal: "modalTarget",
        image: require('../images/cases/target-trading.jpg'),
        text_button: "Saiba mais",
        alt_image: "Case Target Tranding",
        title_card: "Target Tranding",
        hat_card: "A Scale construiu uma extranet e um aplicativo, a fim de possibilitar o acompanhamento...",
        client: require('../images/logos/target-logo.png'),
        logoModal: require('../images/logos/target-logo2.png'),
        clientName: "Target Tranding",
        project: "Extranet Digital",
        segment: "Trade, Importação, Exportação",
        title_aplication_one: "Extranet e App - Dashboard:",
        description: "O objetivo do projeto foi disponibilizar informações do processo de importação aos seus clientes através de extranet e App. O grande desafio destes projeto foi a integração com sistema legado e seus dados densos. Atualmente os clientes Target podem acompanhar o processo bem como informações financeiras através de perfis diferentes de acesso, tanto na extranet quanto no app. Cliente há 4 anos.",
        imgModal: [
            {
                id: 1,
                img: require('../images/cases/produtos/target1.jpg'),
                dimension: "100%",
                alt: "Case Target Trading",
            },
            {
                id: 2,
                img: require('../images/cases/produtos/target2.jpg'),
                dimension: "100%",
                alt: "Case Target Trading",
            },
            {
                id: 3,
                img: require('../images/cases/produtos/target3.jpg'),
                dimension: "100%",
                alt: "Case Target Trading",
            },
            {
                id: 4,
                img: require('../images/cases/produtos/target4.jpg'),
                dimension: "100%",
                alt: "Case Target Trading",
            },
            {
                id: 5,
                img: require('../images/cases/produtos/target5.jpg'),
                dimension: "100%",
                alt: "Case Target Trading",
            },
            {
                id: 6,
                img: require('../images/cases/produtos/target6.jpg'),
                dimension: "100%",
                alt: "Case Target Trading",
            },
            {
                id: 7,
                img: require('../images/cases/produtos/target7.jpg'),
                dimension: "100%",
                alt: "Case Target Trading",
            },
        ]
    },
]
