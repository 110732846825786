import React from "react";

import { VideoSlide } from "../../components";
import { SectionContent } from "../../globalStyles";

const Home = () => {
    
    return (
        <SectionContent id="home">
            <VideoSlide />
        </SectionContent>
    )
}

export default Home