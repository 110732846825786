export const Title = {
    letter_red: 's',
    title_complete: 'erviços',
    title_icons: 'A DIVERSIDADE DE SOLUÇÕES É GRANDE...',

}
export const Email = {
    image: require('../sections/Servicos/icons/white/icon-send.svg'),
}
export const Texto = {
    description: 'Você participa do projeto do início ao fim, trabalhando por meio de metodologias ágeis, cada assunto é abordado no momento certo, em plena sintonia, para definirmos juntos os objetivos e prioridades e assim entregar um software em um período mais curto, com menor risco de insatisfação e sem surpresa no orçamento.'
}

export const DataServicosAtivos = [
    {
        image: require('../images/servicos/servicos-1.svg'),
        alt: 'UM SQUAD PRA CHAMAR DE SEU',
        title: 'UM SQUAD PRA CHAMAR DE SEU',
        text: 'Para cada projeto montamos um time de profissionais qualificados que vão se dedicar ao seu projeto, garantindo as entregas com assertividade e velocidade.',
    },
    {
        image: require('../images/servicos/servicos-2.svg'),
        alt: 'NÓS ESTAREMOS JUNTOS, DO INÍCIO AO FIM PRA SEMPRE',
        title: 'NÓS ESTAREMOS JUNTOS, DO INÍCIO AO FIM PRA SEMPRE',
        text: 'Você participa do projeto do início ao fim, nos mostrando sua necessidade e nós, com nossa expertize, apresentamos a solução.',
        reverse: 'row-reverse',
    },
    {
        image: require('../images/servicos/servicos-3.svg'),
        alt: 'MOBILE OU DESK - TANTO FAZ',
        title: 'MOBILE OU DESK - TANTO FAZ',
        text: 'Entendendo sua necessidade te diremos o que melhor se enquandra ao seu negócio. Mas você também pode trazer a ideia pronta. E está tudo bem!',
    },
    {
        image: require('../images/servicos/servicos-4.svg'),
        alt: 'ADEUS ANO VELHO, FELIZ ANO NOVO',
        title: 'ADEUS ANO VELHO, FELIZ ANO NOVO',
        text: 'Um software antigo que não atende mais a necessiade de seu time, ou precisa implementar novas funcionalidades para aumentar a produtividade? Conte conosco.',
        reverse: 'row-reverse',
    },
    {
        image: require('../images/servicos/servicos-5.svg'),
        alt: 'UM SISTEMA COM A CARA DO SEU NEGÓCIO',
        title: 'UM SISTEMA COM A CARA DO SEU NEGÓCIO',
        text: 'Porque ele precisa dinamizar seu tempo, fazer seu time ser mais produtivo e assertivo. Cada um tem sua personalidade e um sistema também precisa ter a sua. Não dá pra ser mais do mesmo.',
    },
    {
        image: require('../images/servicos/servicos-6.svg'),
        alt: 'SEM SURPRESAS NO ORÇAMENTO',
        title: 'SEM SURPRESAS NO ORÇAMENTO',
        text: 'Cada assunto é abordado no momento certo, em plena sintonia, para definirmos juntos os objetivos e prioridades e entregar um software em um menor período, com menor risco de insatisfação e sem surpresa no orçamento.',
        reverse: 'row-reverse',
    },
];

export const DataServicos = [
    {
        image: require('../sections/Servicos/icons/white/internet-extranet.svg'),
        imageHover: require('../sections/Servicos/icons/red/internet-extranet-hover.svg'),
        alt: 'Intranet e extranet',
        legend: 'Intranet e extranet'
    },
    {
        image: require('../sections/Servicos/icons/white/comercio-eletronico.svg'),
        imageHover: require('../sections/Servicos/icons/red/comercio-eletronico-hover.svg'),
        alt: 'comércio eletrônico, soluções B2C e B2B',
        legend: 'comércio eletrônico, soluções B2C e B2B'
    },
    {
        image: require('../sections/Servicos/icons/white/portais-websites.svg'),
        imageHover: require('../sections/Servicos/icons/red/portais-websites-hover.svg'),
        alt: 'portais, websites e hotsites',
        legend: 'portais, websites e hotsites'
    },
    {
        image: require('../sections/Servicos/icons/white/solucoes-mobile.svg'),
        imageHover: require('../sections/Servicos/icons/red/solucoes-mobile-hover.svg'),
        alt: 'soluções mobile: Ipad, Iphone e Android',
        legend: 'soluções mobile: Ipad, Iphone e Android'
    },
    {
        image: require('../sections/Servicos/icons/white/aplicacoes-web.svg'),
        imageHover: require('../sections/Servicos/icons/red/aplicacoes-web-hover.svg'),
        alt: 'aplicações web, monitoramento e gestão em cloud',
        legend: 'aplicações web, monitoramento e gestão em cloud'
    },
];