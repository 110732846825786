import React from "react";
import {
  DataServicos,
  Title,
  DataServicosAtivos,
} from "../../data/DataServicos";
import {
  Container,
  FirstLetterTitle,
  SectionContent,
  TitleSection,
  Text,
} from "../../globalStyles";
import {
  RowIcons,
  ColumnIcons,
  ImgIcon,
  LegendTxt,
 
  RowAtivos,
  ColumnAtivos,
  TitleAtivo,
  ImgAtivo,
  TitleSecond,
} from "./Servicos.elements";

import AnimationCase, {
  AnimationLeft,
  AnimationRight,
} from "../../components/Animation/Animation";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation } from "swiper";

const Servicos = () => {
  const params = {
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 3000,
    },
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 0.5,
      slideShadows: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 50,
      },
    },
    pagination: true,
    navigation: true,
    modules: [EffectCoverflow, Pagination, Navigation],
  };
  return (
    <SectionContent id="servicos" bgColor="#ffffff">
      <TitleSection color="#58595b">
        <FirstLetterTitle color="#ee3424">{Title.letter_red}</FirstLetterTitle>
        {Title.title_complete}
      </TitleSection>

      <Container>
        {DataServicosAtivos.map((ativo, index) => (
          <RowAtivos
            key={index}
            fd="column"
            style={
              ativo.reverse
                ? { flexDirection: `${ativo.reverse}` }
                : { flexDirection: "" }
            }
          >
            <ColumnAtivos>
              <AnimationRight>
                <ImgAtivo src={ativo.image.default} alt={ativo.alt} />
              </AnimationRight>
            </ColumnAtivos>

            <ColumnAtivos>
              <AnimationLeft>
                <TitleAtivo>{ativo.title}</TitleAtivo>
                <Text color="#58595b" pb="30px" pl="15px" pr="15px">
                  {ativo.text}
                </Text>
              </AnimationLeft>
            </ColumnAtivos>
          </RowAtivos>
        ))}
      </Container>

      <TitleSecond>{Title.title_icons}</TitleSecond>
      <AnimationCase>
        <Swiper {...params} className="mySwiper">
          <RowIcons>
            {DataServicos.map((servico, index) => (
              <SwiperSlide key={index}>
                <ColumnIcons>
                  <ImgIcon src={servico.imageHover.default} alt={servico.alt} />
                  {/* <ImgIconHover src={(servico.imageHover).default} alt={servico.alt} /> */}
                  <LegendTxt>{servico.legend}</LegendTxt>
                </ColumnIcons>
              </SwiperSlide>
            ))}
          </RowIcons>
        </Swiper>
      </AnimationCase>

      {/* <EmailBlock>
        <Container>
          <FormInscreva />
        </Container>
      </EmailBlock> */}
    </SectionContent>
  );
};

export default Servicos;
