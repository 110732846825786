import { FormContato } from "../../components";
import imgContato from '../../images/bg-contato.jpg';
import { SectionContent } from "../../globalStyles";
const Contato = () => {
    return (
        <SectionContent id="contato" pb="50px" bgColor="#1a1a1a" imgBG={imgContato}>
                <FormContato />
        </SectionContent>
    )
}

export default Contato