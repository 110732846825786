import styled from "styled-components";

export const RowAtivos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  @media screen and (max-width: 768px) {
    flex-direction: ${(props) => props.fd} !important;
  }
`
export const ImgAtivo = styled.img`
  width: 65%;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
export const ColumnAtivos = styled.div`
width: 50%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
export const TitleAtivo = styled.h3`
  color: #58595b;
  font-weight: 900;
  font-size: 1.8rem;
  text-transform: uppercase;
  padding: 15px;
`

export const RowIcons = styled.div`
  /* display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 15px 0; */
`
export const ImgIcon = styled.img`
    height: 160px;
    width: 100%;
    margin: 15px 0;
    transition: .5s;
`
export const TitleSecond = styled.h3`
text-align: center;
  color: #ee3424;
  font-weight: 900;
  font-size: 1.8rem;
  text-transform: uppercase;
  padding: 15px;
  `
export const ImgIconHover = styled.img`
  /* display: none;
  position: absolute;
  top: -30px;
  width: 200px;
  margin: 0;
  z-index: 99;
  transition: .2s; */
`
export const LegendTxt = styled.p`
    font-size: 1.2rem;
    color: #58595b;
    text-align: center;
`

export const ColumnIcons = styled.div`
  position: relative;
  height: auto;
  @media screen and (max-width: 800px) {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
  @media screen and (max-width: 600px) {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
  `
  export const EmailBlock = styled.div`
    display: flex;
    align-items: center;
    background: #ee3424;
    min-height: 100px;
    padding: 0;
  ` 
  export const FormInputBlock = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
    }
  `
  export const IconMail = styled.img`
    max-width: 150px;
  `
  export const Input = styled.input`
    background: transparent;
    border-radius: 0;
    border: 0;
    color: #ffffff;
    max-width: 560px;
    width: 100%;
    border-bottom: 2px solid #ffffff;
    margin: 0 25px;
    height: 40px;
    padding: 5px;

    ::placeholder {
       color: #ffffff;
       font-size: 1rem;
   }

   @media screen and (max-width: 768px) {
        margin: 0;
    }
  `
  export const Button = styled.button`
  cursor: pointer;
  background: #ffffff;
  border-radius: 5px;
  border: 0;
  color: #ee3424;
  font-weight: bold;
  width: 150px;
  height: 40px;
  font-size: 1.2rem;
  box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 768px) {
        margin: 20px 0;
        width: 100%;
    }
  `