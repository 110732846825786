import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
* {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html{
  height: auto !important;
}
body {
  opacity: 1;
  visibility: visible;
  animation: 0s ease 0s 1 normal none running none;
  overflow-x: hidden;
}
.rec-slider-container{
  margin: 0 !important;
}
/* Lightbox do carousel dos Cases */
.ril__image,
.ril__imagePrev,
.ril__imageNext{
    max-height: 520px;
}
`

export const FullContainer = styled.section`
  position: relative;
  width: 100%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
`
export const Container = styled.div`
z-index: 1;
width: 100%;
max-width: 1170px;
margin-right: auto;
margin-left: auto;
padding-right: 15px;
padding-left: 15px;
background: ${(props) => props.bg};
padding-left: ${(props) => props.pl};
padding-right: ${(props) => props.pr};
padding-bottom: ${(props) => props.pb};
margin-left: ${(props) => props.ml};
margin-right: ${(props) => props.mr};
max-width: ${(props) => props.mw};
box-shadow: ${(props) => props.bs};

@media screen and (max-width: 991px) {
    padding-right: 15px;
    padding-left: 15px;
  }
`
export const SectionContent = styled.div`
    position: relative;
    background: ${(props) => props.bgColor};
    padding-bottom: ${(props) => props.pb};
    background-image: url(${(props) => props.imgBG});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

.swiper {
  width: 100%;
  height: 420px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}
.swiper-slide.swiper-slide-visible img{
  -webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	filter: gray; /* IE */
}
.swiper-slide.swiper-slide-active img{
  height: 275px;
  position: relative;
  margin-top: -30px;
  -webkit-filter: none;
	filter: none;
}
.swiper-slide.swiper-slide-active p{
  font-size: 1.4rem;
  padding-bottom: 15px;
}
.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right{
      background-image: none !important;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after,
.swiper-button-next:after, .swiper-rtl .swiper-button-next:after{
  color: #ee3424;
    background-color: rgb(0 0 0 / 50%);
    top: 0;
    padding: 12px;
}
`
export const TitleSection = styled.h1`
  padding-top: 75px;
  padding-bottom: 75px;
  color: ${(props) => props.color};
  font-size: 3rem;
  letter-spacing: 2px;
  text-align: center;
`
export const FirstLetterTitle = styled.span`
  color: ${(props) => props.color};
`

export const Text = styled.p`
  color: ${(props) => props.color};
  font-weight: 500;
  font-size: 1.1rem;
  text-indent: ${(props) => props.textIndent};
  padding-right: ${(props) => props.pr};
  padding-left: ${(props) => props.pl};
  padding-top: ${(props) => props.pt};
  padding-bottom: ${(props) => props.pb};
  font-size: ${(props) => props.fz};
  font-weight: ${(props) => props.fb};
  text-align: ${(props) => props.ta};
`
export default GlobalStyle