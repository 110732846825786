import { Container, SectionContent, Text } from "../../globalStyles";

const Footer = () => {
    return(
        <SectionContent id="footer" bgColor="#ee3424">
            <Container>
                <Text fb="400" color="#ffffff" fz="1rem" pt="25px" pb="25px" ta="center">Scale Systems - 2022 - Todos os Direitos Reservados</Text>
            </Container>
        </SectionContent>
    )
}

export default Footer