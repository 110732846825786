import React from "react";
import { CarouselLogos } from "../../components";
import { ImgSobre } from "../../data/DataSobre";
import { Container, FirstLetterTitle, SectionContent, TitleSection } from "../../globalStyles";
import { CardContent, CardLeft, CardRight, CardTitle, CardText, ImageSobre, CardCarousel } from "./Sobre.elements";
import { AnimationLeft, AnimationRight } from "../../components/Animation/Animation";

const Sobre = () => {
    return (
        <SectionContent id="sobre" pb='0' bgColor='#ee3424'>

            <TitleSection color="#000000"><FirstLetterTitle color='#ffffff'>s</FirstLetterTitle>obre</TitleSection>


            <Container pl='0' pb='25px' ml='auto' mr='auto' mw='1200px'>
                <CardContent>
                    <CardLeft>
                        <AnimationLeft>
                            <CardTitle pb='30px'>DESENVOLVIMENTO DE SOFTWARE EM PLATAFORMA WEB</CardTitle>

                            <CardText pb='15px'>
                                Uma empresa multidisciplinar de tecnologia que desenvolve
                                sistemas inteiramente personalizados para seu negócio, de
                                acordo com sua necessidade. É isso que somos!
                            </CardText>
                            <CardText>
                                Nossa missão no mundo é fazer com que o sistema
                                desenvolvido se encaixe perfeitamente ao dia a dia da sua
                                empresa, aumentando a produtividade e facilitando os
                                processos.
                            </CardText>
                        </AnimationLeft>
                    </CardLeft>

                    <CardRight>
                        <AnimationRight>
                            <ImageSobre src={(ImgSobre.image).default} alt={ImgSobre.alt} />
                        </AnimationRight>
                    </CardRight>
                </CardContent>
            </Container>

            <Container bs='0px 0px 8px rgb(0, 0, 0, 0.6)' pl='0' pr='0' ml='auto' mr='auto' mw='100%' bg='#ffffff'>
                <CardContent pt="15px" pb="15px">
                    <CardTitle size='1.8rem' pt="10px" color='#58595b'>+ 20 ANOS DE EXPERIÊNCIA</CardTitle>
                    <CardCarousel>
                        <CarouselLogos />
                    </CardCarousel>
                </CardContent>
            </Container>


        </SectionContent>
    )
}

export default Sobre