import { Link } from "react-scroll";
import styled from "styled-components";
import { Container } from "../../globalStyles";

export const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
text-align: center;
transition: .5s;

@media screen and (max-width: 960px){
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 60px;
    left: ${({ click }) => (click ? 0 : '-100%')};
    opacity: 1;
    transition: all 0.5s ease;
    background: #1a1a1a;
}
`
export const NavItem = styled.li`
height: 65px;
font-weight: normal;
cursor: pointer;

.active{
    color: rgb(153 153 153) !important;
    font-weight: bold;
    border-bottom: 3px solid rgb(89 89 92);

}
&:hover{
    border-bottom: 3px solid rgb(89 89 92);

}

@media screen and (max-width: 960px){
    width: 100%;

    &:hover{
        border: none;
    }
}
`
export const MobileIcon = styled.div`
    display: none;
    transition: .5s;

    @media screen and (max-width: 960px){
        transition: .5s;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
`
export const NavLinks = styled(Link)`
    color: #ffffff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;

    @media screen and (max-width: 960px){
        text-align: center;
        padding: 15px 0px;
        width: 100%;
        display: table;

        &:hover{
            color: #58595b;
            transition: all 0.3s ease;
        }
    }
`
export const Logo = styled.img`
    width: 100%;
    max-width: 200px;
    transition: .5s;

    @media(max-width: 991px){
        max-width: 175px;
        transition: .5s;
    }
`
export const Nav = styled.nav`
    background-color: rgb(0 0 0 / 0.6);
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
    transition: .5s;

    &.white{
        height: 50px;
        background-color: rgb(255 255 255 / 0.95);
        transition: .5s;

        @media screen and (min-width: 961px){
            ${NavLinks}{
                color: #58595b;
                padding: 0 25px;
                font-size: 1rem;
                transition: .5s;
            }
                ${NavItem}{
                height: 50px;
                transition: .5s;
            }
        }
        ${Logo}{
            width: 100%;
            max-width: 130px;
            transition: .5s;
        }
        ${MobileIcon}{
            top: -10px;
            transition: .5s;
        }
    }
`

export const NavbarContainer = styled(Container)`
    display: flex;
    justify-content: space-between;
    height: 65px;

    ${Container};

    @media screen and (max-width: 960px){
        height: 40px;
    }
`

export const NavLogo = styled.div`
    display: flex;
    align-items: center;
    justify-self: start;
    cursor: pointer;
`
export const Contato = styled.span`
    color: #ee3424;
    font-weight: bold;
`