export const Title = {
    letter_red: 'c',
    title_complete: 'ontato',
}
export const Texto = {
    description: 'Você participa do projeto do início ao fim, trabalhando por meio de metodologias ágeis, cada assunto é abordado no momento certo, em plena sintonia, para definirmos juntos os objetivos e prioridades e assim entregar um software em um período mais curto, com menor risco de insatisfação e sem surpresa no orçamento.'
}
export const SelectAssunto = [
    {
        id: 1,
        option: 'Proposta comercial'
    },
    {
        id: 2,
        option: 'Serviços e soluções'
    },
    {
        id: 3,
        option: 'Oportunidade de trabalho'
    },
    {
        id: 4,
        option: 'Outros'
    },
];