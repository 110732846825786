import React, { useEffect, useState } from "react";
import { TextCursor, TitleSlide } from "./VideoSlide.elements";
import PropTypes from 'prop-types';

const TypeWriter = ({ value }) => {
    const [text, setText] = useState('');

    const typeWriter = (text, i = 0) => {
        if (i < value.length) {
            setText(text.slice(0, i + 1));
            setTimeout(() => {
                typeWriter(text, i + 1);
            }, 300);
        }
    };

    useEffect(() => {
        typeWriter(value);
    }, []);

    return (
        <TitleSlide>
            {text}
            <TextCursor />
        </TitleSlide>
    );
}

TypeWriter.propTypes = {
    value: PropTypes.string.isRequired
};

export default TypeWriter;