import React, { Component } from "react"
import ReactDOM from "react-dom"
import Slider from "react-slick";
import { Background, ModalWrapper, ModalContent, ModalContentTop, ModalImg, ModalText, ModalClose, ListModal, ItemList, ItemBold, ModalSlider, ImgSlider, NextSlide, PrevSlide, Prev, Next } from "./Modal.elements";
import Lightbox from "react-image-lightbox";

const modalRoot = document.getElementById('modal-root')

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <NextSlide onClick={onClick}>
            <Next />
        </NextSlide>
    )
}
const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <PrevSlide onClick={onClick}>
            <Prev />
        </PrevSlide>
    )
}

class Modal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            imgModal: null,
            current: ""
        };
    }
    handleClickImage = (e, imagesmodal) => {
        e && e.preventDefault();

        this.setState({
            current: imagesmodal
        });
    };

    handleCloseModal = e => {
        e && e.preventDefault();

        this.setState({
            current: ""
        });
    };
    render() {
        const { current } = this.state;

        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            slidesToShow: 3,
            speed: 500,
            autoplaySpeed: 5000,
            adaptiveHeight: true,
            centerPadding: '60px',
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return ReactDOM.createPortal(
            <React.Fragment>
                {this.props.show && (
                    <Background>
                        <ModalWrapper>

                            <ModalContentTop>
                                <ModalImg src={this.props.logoModal ? this.props.logoModal : this.props.client} alt={this.props.alt_image} />
                                <ListModal>
                                    <ItemList><ItemBold>Cliente:</ItemBold> {this.props.clientName}</ItemList>
                                    <ItemList><ItemBold>Segmento:</ItemBold> {this.props.segment}</ItemList>
                                    <ItemList><ItemBold>Projeto:</ItemBold> {this.props.project}</ItemList>
                                </ListModal>
                            </ModalContentTop>

                            <ModalContent>
                                <ModalText>{this.props.description}</ModalText>
                                <ModalText>{this.props.description_two}</ModalText>
                                <br/>
                                <ModalText>{this.props.description_three}</ModalText>
                            </ModalContent>

                            <Slider {...settings} style={{ paddingLeft: 15, paddingRight: 15 }}>

                                {this.props.imgModal.map((imagesmodal, index) => (
                                    <ModalSlider key={index}>
                                        <ImgSlider src={imagesmodal.img} style={{ width: imagesmodal.dimension }} alt={imagesmodal.alt}
                                            onClick={e => this.handleClickImage(e, imagesmodal.img)} />
                                    </ModalSlider>
                                ))}

                            </Slider>
                            {current && (
                                <Lightbox
                                    mainSrc={current}
                                    onCloseRequest={this.handleCloseModal}
                                />
                            )}
                            <ModalClose onClick={this.props.onHide} />

                        </ModalWrapper>
                    </Background>
                )}
            </React.Fragment>,
            modalRoot
        )
    }
}
export default Modal;