import React, { useState, useEffect, useRef } from "react";
import { AnimateCase, AnimateLeft, AnimateRight } from "./Animation.elements";

const AnimationCase = ({ children }) => {

    const [isVisible, setVisible] = useState(true);
    const domRef = useRef();
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        }, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });
        observer.observe(domRef.current);
        return () => observer.unobserve(domRef.current);
    }, []);
    return (
        <AnimateCase
            className={`fade-in-case ${isVisible ? 'case-visible' : ''}`}
            ref={domRef}>
            {children}
        </AnimateCase>
    )
}

const AnimationLeft = ({ children }) => {

    const [isVisible, setVisible] = useState(true);
    const domRef = useRef();
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        }, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });
        observer.observe(domRef.current);
        return () => observer.unobserve(domRef.current);
    }, []);
    return (
        <AnimateLeft
            className={`fade-in-left ${isVisible ? 'left-visible' : ''}`}
            ref={domRef}>
            {children}
        </AnimateLeft>
    )
}

const AnimationRight = ({ children }) => {

    const [isVisible, setVisible] = useState(true);
    const domRef = useRef();
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        }, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });
        observer.observe(domRef.current);
        return () => observer.unobserve(domRef.current);
    }, []);
    return (
        <AnimateRight
            className={`fade-in-right ${isVisible ? 'right-visible' : ''}`}
            ref={domRef}>
            {children}
        </AnimateRight>
    )
}
export default AnimationCase;
export { AnimationLeft, AnimationRight }