import React, { Component } from "react";
import {
    FirstLetter,
    TopTitle,
    CardTitleLogo,
    RowCases,
    ColumnCases,
    ImgCases,
    InfoCases,
    ButtonCases
} from "./Case.elements";
import { SectionContent } from "../../globalStyles";
import { TitleSection } from '../../data/DataCases';
import Modal from '../Modal/Modal';
import AnimationCase from "../Animation/Animation";

class Case extends Component {

    state = {
        showModal: 0,
    };
    getModal = value => {
        this.setState({ showModal: value });
    };
    hideModal = value => {
        this.setState({ showModal: 0 });
    };
    render() {
        return (
            <SectionContent id="cases" bgColor='#000000'>
                <TopTitle color="#58595b">
                    <FirstLetter color="#ee3424">{TitleSection.letter_red}</FirstLetter>{TitleSection.title_complete}
                </TopTitle>

                <RowCases>
                    {this.props.data.map((data, key) => (
                        <ColumnCases key={key}>
                            <AnimationCase>
                                <ImgCases src={data.image} alt={data.image_alt} />
                                <InfoCases>
                                    <CardTitleLogo src={data.client} alt={data.image_alt} />
                                    <ButtonCases onClick={() => this.getModal(data.id)}>{data.text_button}</ButtonCases>
                                </InfoCases>
                            </AnimationCase>

                            <Modal
                                show={this.state.showModal === data.id}
                                onHide={() => this.hideModal(data.id)}
                                client={data.client}
                                logoModal={data.logoModal}
                                clientName={data.clientName}
                                segment={data.segment}
                                project={data.project}
                                description={data.description}
                                description_two={data.description_two}
                                description_three={data.description_three}
                                imgModal={data.imgModal}

                            />
                        </ColumnCases>
                    ))}
                </RowCases>
            </SectionContent>
        );
    }
}
export default Case