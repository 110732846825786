import styled from "styled-components";
import { MdClose } from "react-icons/md"
import { TitleSection, FirstLetterTitle } from "../../globalStyles";

export const TopTitle = styled(TitleSection)`
    ${TitleSection}
`
export const FirstLetter = styled(FirstLetterTitle)`
    ${FirstLetterTitle}
`
export const RowCases = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
`
export const InfoCases = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
`

export const ColumnCases = styled.div`
  position: relative;
  -ms-flex: 33.33333333333333%;
  flex: 33.33333333333333%;
  max-width: 33.33333333333333%;
  padding: 0;

  &:hover ${InfoCases}{
    height: 100%;
  }

  @media screen and (max-width: 800px) {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
  @media screen and (max-width: 600px) {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
`
export const ImgCases = styled.img`
display: block;
  margin:0;
  vertical-align: middle;
  width: 100%;
  height: auto;
`
export const ButtonCases = styled.button`
  color: white;
  font-size: 1rem;
  padding: 10px 20px;
  position: absolute;
  top: 75%;
  left: 50%;
  border: 0;
  border-radius: 10px;
  background: #ee3424;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
`
export const CardTitleLogo = styled.img`
  font-size: 1.2rem;
  font-weight: 400;
  position: absolute;
  max-width: 200px;
  top: 40%;
  left: 50%;
  color: #ffffff;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
`
export const Background = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s ease;
`
export const ModalWrapper = styled.div`
    max-width: 800px;
    width: 100%;
    min-height: 500px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    background: #ffffff;
    color: #ee3424;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    z-index: 10;
    border-radius: 10px;
    transition: .5s ease;
`
export const ModalImg = styled.img`
    width: 100%;
    height: 100%;
    background: #000000;
    border-radius: 10px 0 0 10px;
`
export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.8;
    color: #141414;
`
export const ModalTitle = styled.h2`
    font-size: 1.8rem;
    font-weight: bold;
`
export const ModalText = styled.p`
    font-size: 1rem;
`
export const ModalClose = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    padding: 0;
    z-index: 10;
`