import React, { useState } from "react";
import {
  Text,
  TitleSection,
  FirstLetterTitle,
  Container,
} from "../../globalStyles";
import { Title, SelectAssunto } from "../../data/DataContato";
import ReCAPTCHA from "react-google-recaptcha";

import {
  ColumnContent,
  Input,
  RowContent,
  Formulario,
  RowInput,
  Select,
  TextArea,
  InputFile,
  Button,
  BlockCapcha,
  WhatsApp,
  Facebook,
  Linkedin,
  Localizacao,
  Telefone,
  List,
  Item,
  LinkCNTT,
  LogoScale,
  DivLogo,
} from "./FormContato.elements";
import { AnimationLeft, AnimationRight } from "../Animation/Animation";

const FormContato = () => {

  const [isVarified, setIsVerified] = useState(true);

  const handleOnChange = () => setIsVerified(!isVarified);


  return (
    <Container>
      <TitleSection color="#58595b">
        <FirstLetterTitle color="#ee3424">{Title.letter_red}</FirstLetterTitle>
        {Title.title_complete}
      </TitleSection>
      <RowContent>
        <ColumnContent>
          <AnimationLeft>
            <Formulario
              method="post"
              action="/email/contato/"
              encType="multipart/form-data"
            >
              <Input
                type="text"
                name="nome"
                id="nome"
                placeholder="* Nome"
                required
              />
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="* E-mail"
                required
              />

              <RowInput>
                <div className="separador-l">
                  <Input
                    className="inputTel"
                    mask="(99) 99999-9999"
                    type="tel"
                    name="celular"
                    id="celular"
                    placeholder="* Celular"
                    required
                  />
                </div>

                <div className="separador-r">
                  <Input
                    className="inputTel"
                    mask="(99) 9999-9999"
                    type="tel"
                    name="telefone"
                    id="telefone"
                    placeholder="Telefone"
                  />
                </div>
              </RowInput>

              <Select name="assunto" id="assunto" required>
                <option value="">Escolha um assunto</option>
                {SelectAssunto.map((assunto, id) => (
                  <option key={id} value={assunto.option}>
                    {assunto.option}
                  </option>
                ))}
              </Select>

              <TextArea
                name="mensagem"
                id="mensagem"
                placeholder="* Mensagem"
                required
              ></TextArea>

              <InputFile>
                <Input
                  type="file"
                  name="anexo"
                  id="anexo"
                  accept=".pdf, .doc, .docx"
                />
              </InputFile>

              {/* PRODUÇÃO */}
              <BlockCapcha>
                  <ReCAPTCHA
                      sitekey="6LcpZychAAAAADa71D3yq83y6JS6Yx3wh-rbjaqT"
                      onChange={handleOnChange}
                  />
              </BlockCapcha>

              {/* DEV */}
              {/* <BlockCapcha>
                <ReCAPTCHA
                  sitekey="6Le_x6kdAAAAAF9ddoi8_ADHeCfBu5HwX4GfRjgr"
                  onChange={handleOnChange}
                />
              </BlockCapcha> */}

              <Button
                disabled={isVarified}
                type="submit"
                name="enviar"
                mt="15px"
                mb="15px"
                bg="transparent"
                mw="100%"
                br="5px"
                color="#ffffff"
              >
                Enviar
              </Button>
            </Formulario>
          </AnimationLeft>
        </ColumnContent>

        <ColumnContent>
          <AnimationRight>
            <Text fz="1.5rem" fb="400" pb="30px" color="#ffffff">
              <Telefone /> +55 (11) 3062-0211
            </Text>
            <Text fz="1.5rem" fb="400" pb="30px" color="#ffffff">
              <Localizacao /> Av. Brigadeiro Luiz Antônio, 487 Sala 42 / 4º
              andar - Bela Vista - São Paulo - SP
            </Text>

            <List>
              <Item>
                <LinkCNTT
                  href="https://www.linkedin.com/company/scale-systems/mycompany/"
                  target="_blank"
                >
                  <Linkedin />
                </LinkCNTT>
              </Item>
              <Item>
                <LinkCNTT
                  href="https://www.facebook.com/scalesystems"
                  target="_blank"
                >
                  <Facebook />
                </LinkCNTT>
              </Item>
              <Item>
                <LinkCNTT href="#" target="_blank">
                  <WhatsApp />
                </LinkCNTT>
              </Item>
            </List>

            <DivLogo>
              <LogoScale src={require("../../images/logo_scale_square.png")} />
            </DivLogo>
          </AnimationRight>
        </ColumnContent>
      </RowContent>
    </Container>
  );
};

export default FormContato;
